import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlocTitle from "../components/elements/bloc-title";
import BlocContent from "../components/elements/bloc-content";

const LegalPage = ({ data }) => {
  return (
    <Layout>
      <div className="bg-black font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra uppercase"
            content={data.strapiMentionLegale.title}
          />
        </div>
        <div className="flex justify-center px-3 py-12 text-xs  lg:px-40 xl:px-20">
          <BlocContent
            className="lg:text-base"
            content={data.strapiMentionLegale.content.data.content}
            animate="false"
          />
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query strapiLegalQuery {
    strapiMentionLegale {
      title
      content {
        data {
          content
        }
      }
    }
  }
`;

export default LegalPage;
